import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { partnerList } from "./features/partner/partnerSlice";
import logger from "redux-logger";
import { specialityList } from "./features/speciality/specialitySlice";
import { userList } from "./features/user/userSlice";
import { categoryList } from "./features/category/categorySlice";
import { blogList } from "./features/blog/blogSlice";
import {sharedSlice} from "./features/shared/sharedSlice"
import { testimonialList } from "./features/testimonial/testimonialSlice";
import { sessionList } from "./features/session/sessionSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { timings } from "./features/timings/timingsSlice";
import { paymentsSlice } from "./features/payment/paymentSlice";
import { notificationList } from "./features/notifications/notificationsSlice";
import { transactionListDetails } from "./features/transaction/transactionSlice";
import { invoiceSlice } from "./features/invoice/invoiceSlice";
import { languageSlice } from "./features/language/languageSlice";


const rootReducer = combineReducers({
  partnerList: partnerList.reducer,
  specialityList: specialityList.reducer,
  userList: userList.reducer,
  categoryList: categoryList.reducer,
  blogList: blogList.reducer,
  sharedActions: sharedSlice.reducer,
  testimonialList : testimonialList.reducer,
  sessionList: sessionList.reducer,
  timingUpdations:timings.reducer,
  payment:paymentsSlice.reducer,
  notificationList: notificationList.reducer,
  transactionDetails:transactionListDetails.reducer,
  invoice: invoiceSlice.reducer,
  language:languageSlice.reducer
});


const persistConfig = {
  key: "root",
  storage,
  whitelist: ["sharedActions","timingUpdations","blogList"], // only 'auth' reducer will be persisted
  // blacklist: ['someReducer'] use blacklist to exclude specific reducers from being persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    })
      .concat(logger as any)
});

const persistor = persistStore(store);
export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
