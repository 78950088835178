import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {docoumentUpload, setFormDetails, setPartnerModalStatus, setPartnerProfileImages } from "../shared/sharedSlice";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const API_URL_AUTH = process.env.REACT_APP_AUTH_API_URL;
const GET_ALL_PARTNER = `${API_URL}/getAllAdminPartners`;
const VERIFY_PARTNER = `${API_URL_AUTH}/verifyPartnerByAdmin`;
const CREATE_PARTNER = `${API_URL_AUTH}/createPartnerByAdmin`;
const COMMON_FILE_UPLOAD = `${API_URL_AUTH}/fileUpload`;
const UPDATE_PARTNER_DETAILS = `${API_URL_AUTH}/updatePartnerAccount`;
const GET_PARTNER_BY_ID = `${API_URL}/getPartner`;
const PARTNER_BY_ID = `${API_URL_AUTH}/deletePartner`;

export const getParnters = createAsyncThunk(
  "getParnters",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_ALL_PARTNER}?page=${page}&limit=${limit}&search=${search}`, {});
      data['page'] = page;
      data['limit'] = limit;
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const createPartner = createAsyncThunk(
  "createPartner",
  async (values: any, { rejectWithValue, dispatch,getState }) => {
    try {
      const store:any = getState();
      const { page, limit } = store.partnerList;
      const { data } = await axios.patch(`${CREATE_PARTNER}`, values);
      dispatch(getParnters({ page: page, limit: limit }))
      dispatch(setPartnerModalStatus(false))
      dispatch(setFormDetails({}))
      dispatch(docoumentUpload([]))
      notify(data.responseMessage, 'success');
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const verifyPartner = createAsyncThunk(
  "verifyPartner",
  async (values: any, { rejectWithValue, dispatch,getState }) => {
    try {
      const store:any = getState();
      const { page, limit } = store.partnerList;
      const { id, verifiedStatus } = values
      const { data } = await axios.patch(`${VERIFY_PARTNER}/${id}`, { verifiedStatus: verifiedStatus });
      dispatch(getParnters({ page: page, limit: limit }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const commonFileUpload = createAsyncThunk(
  "commonFileUpload",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const storeData: any = getState()
      const formDetail = storeData.sharedActions?.formDetails
      const { data } = await axios({
        method: "post",
        url: `${COMMON_FILE_UPLOAD}`,
        data: values,
        headers: { "Content-Type": "multipart/form-data" },
      })
      const docs = data?.data?.map((image: any) => image.url)
      if (formDetail?.documents) {
        dispatch(setFormDetails({...formDetail, documents: [...formDetail.documents,...docs] }))
      } else {
        dispatch(setFormDetails({...formDetail, documents: docs }))
      }
      // dispatch(setFormDetails({ ...formDetail, documents: [...formDetail.documents,...docs] }))
      return data
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "uploadImage",
  async (values: any, { rejectWithValue, dispatch ,getState}) => {
    try {
      const storeData: any = getState()
      const formDetail = storeData.sharedActions?.formDetails
      const { formData, type } = values
      const { data } = await axios({
        method: "post",
        url: `${COMMON_FILE_UPLOAD}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (type === 'profilePicture') {
        dispatch(setPartnerProfileImages(data))
      } else if (type === 'cover') {
        const coverImages = data?.data?.map((image: any) => image.url)
        if(formDetail.coverImage){
          dispatch(setFormDetails({ ...formDetail, coverImage: [...formDetail.coverImage,...coverImages] }))
        }else{
          dispatch(setFormDetails({...formDetail, coverImage: coverImages }))
        }
      }
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updatePartnerDetails = createAsyncThunk(
  "updatePartnerDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { id , ...newValues } = values;
      const { data } = await axios.post(`${UPDATE_PARTNER_DETAILS}/${id}`, newValues);
      dispatch(setPartnerModalStatus(false))
      dispatch(setFormDetails({}))
      dispatch(docoumentUpload([]))
      notify(data.responseMessage, 'success');
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const getPartnerById = createAsyncThunk(
  "getPartnerById",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { id } = values;
      const { data } = await axios.get(`${GET_PARTNER_BY_ID}/${id}`,{});
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "deletePartner",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const { id} = values;
      const store: any = getState();
      const {page,limit} =store.partnerList;
      const { data } = await axios.delete(`${PARTNER_BY_ID}/${id}`, {  });
      dispatch(getParnters({ page: page, limit: limit }));
      notify(data.responseMessage, 'success')
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

