import { createSlice } from "@reduxjs/toolkit";
import { addSpeciality, getSpeciality } from "./_specialityAction";

const initialState: any = {
  data: [],
  isLoading: false,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  page:1,
  limit:10,
  search:'',
  addSpecialityLoading: false
};

export const specialityList = createSlice({
  name: "specialityList",
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getSpeciality.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getSpeciality.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
        state.page = payload.page
        state.limit = payload.limit
      })
      .addCase(getSpeciality.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(addSpeciality.pending, (state) => {
        state.addSpecialityLoading = true;
        state.isSuccess = false;
      })
      .addCase(addSpeciality.fulfilled, (state, { payload }) => {
        state.addSpecialityLoading = false;
        state.isSuccess = true;
      })
      .addCase(addSpeciality.rejected, (state, { payload }) => {
        state.addSpecialityLoading = false;
        state.isSuccess = false;
      });
  },
});
