import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from "yup";
import FieldInputText from '../apps/common/InputFeilds/InputTextField';
import { REQUIRED } from '../../../utils/const';
import { getTimimgs, updatetimingsDetails } from '../../../redux/features/timings/_timingsAction';

function AddWaitingTime() {
  const dispatch: any = useDispatch();
  const { timingData } = useSelector((state: any) => state?.timingUpdations);

  React.useEffect(() => {
    // Fetch timing data on component mount
    dispatch(getTimimgs());
  }, [dispatch]);

  const timingFormValidation = Yup.object().shape({
    waitingListExpiryTime: Yup.number().min(1, REQUIRED).required(REQUIRED),
    notificationPriorTime: Yup.number().min(1, REQUIRED).required(REQUIRED)
  });

  const timingFormik: any = useFormik({
    initialValues: {
      waitingListExpiryTime: '',
      notificationPriorTime: ''
    },
    validationSchema: timingFormValidation,
    onSubmit: (values: any) => {
      dispatch(updatetimingsDetails(values));
    },
  });

  // Update form values after timingData is fetched
  React.useEffect(() => {
    if (timingData) {
      timingFormik.setValues({
        waitingListExpiryTime: timingData?.waitingListExpiryTime || '',
        notificationPriorTime: timingData?.notificationPriorTime || '',
      });
    }
  }, [timingData, timingFormik.setValues]);

  return (
    <div className='addWaitTime'>
      <FormikProvider value={timingFormik}>
        <Form onSubmit={timingFormik.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-12 mb-6">
              <Form.Group>
                <Field
                  name="waitingListExpiryTime"
                  validate={timingFormValidation}
                  type="number"
                  label="Appointment Waiting Time (min)"
                  placeholder="Enter waiting time"
                  component={FieldInputText}
                />
              </Form.Group>
            </div>

            <div className="col-sm-12 col-md-12 mb-6">
              <Form.Group>
                <Field
                  name="notificationPriorTime"
                  validate={timingFormValidation}
                  type="number"
                  label="Notification Prior Time (min)"
                  component={FieldInputText}
                />
              </Form.Group>
            </div>
          </div>

          <Modal.Footer>
            <Button type="submit" className="primaryBtn active">
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </div>
  );
}

export { AddWaitingTime };
