import { createSlice } from "@reduxjs/toolkit";
import { addLanguage, getLanguages, updateLanguage } from "./_languageAction";

const initialState: any = {
  data: [],
  isLoading: false,
  isSuccess: false,
  totalRecord: 0,
  page: 1,
  limit: 10,
  search: '',
  addLoading: false,
};

export const languageSlice = createSlice({
  name: "language",
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getLanguages.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getLanguages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.totalRecord = payload.totalRecord;
        state.page = payload.page;
        state.limit = payload.limit;
      })
      .addCase(getLanguages.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(addLanguage.pending, (state) => {
        state.addLoading = true;
        state.isSuccess = false;
      })

      .addCase(addLanguage.fulfilled, (state, { payload }) => {
        state.addLoading = false;
        state.isSuccess = true;
      })
      .addCase(addLanguage.rejected, (state, { payload }) => {
        state.addLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })


      .addCase(updateLanguage.pending, (state) => {
        state.addLoading = true;
        state.isSuccess = false;
      })

      .addCase(updateLanguage.fulfilled, (state, { payload }) => {
        state.addLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateLanguage.rejected, (state, { payload }) => {
        state.addLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});
