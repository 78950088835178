import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'
import socketIOClient from "socket.io-client";
import { SOCKET_ENDPOINT } from '../../../../config/endPoints'
import { AuthModel, getAuth } from '../../../../app/modules/auth'
import { notify } from '../../../../utils/shared'
import NotificationIcon from '../../../../app/icons/NotificationIcon'
import NotificationIconGreyIcon from '../../../../app/icons/NotificationIconGreyIcon'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTractions } from '../../../../redux/features/payment/_paymentAction'
import { WITHDRAW_REQUEST } from '../../../../utils/const'
const socket = socketIOClient(SOCKET_ENDPOINT,{
  transports: ['websocket'],
});

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const Topbar: FC = () => {
  const partnerId = useSelector((state:any) => state.sharedActions.formDetails._id)
  const dispatch:any = useDispatch();
  const {config} = useLayout()
  const [auth, setAuth] = useState(getAuth());

  useEffect(() => {
    const socket = socketIOClient(SOCKET_ENDPOINT,{
      transports: ['websocket'],
    });
    if(socket){
    socket.on("notification", (data: any) => { 
      if (data.body?.receiverId === auth?._id) {
        notify(data.body?.message, 'success');
      }
      if(data.body.notificationType===WITHDRAW_REQUEST){
        dispatch(getAllTractions({ id: partnerId, page: 1, limit: 10 }))
      }
    });
    return () => {
      socket.off("notification");
      socket.disconnect();
    };
  }
  }, []);
  
  return (
    <div className='d-flex align-items-stretch flex-shrink-0 me-5'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTIcon iconName='chart-simple' className={toolbarButtonIconSizeClass} />
        </div>
      </div> */}

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {/* <KTIcon iconName='element-plus' className={toolbarButtonIconSizeClass} /> */}
          {/* <NotificationIcon/> */}
          <NotificationIconGreyIcon/>
        </div>
        <HeaderNotificationsMenu />
      </div>

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTIcon iconName='message-text-2' className={toolbarButtonIconSizeClass} />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div> */}



      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center mr-3', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          style={{paddingLeft:10,paddingRight:10}}
        >
         <span className='font-weight-bold btn border-1 btn btn-outline'> Admin</span>
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' /> */}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTIcon iconName='text-align-left' className='fs-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
