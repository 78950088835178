const DashboardIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9883 2.2975C13.3009 1.98505 13.7247 1.80952 14.1667 1.80952C14.6086 1.80952 15.0325 1.98505 15.345 2.2975L17.7025 4.655C18.015 4.96755 18.1905 5.3914 18.1905 5.83334C18.1905 6.27528 18.015 6.69912 17.7025 7.01167L15.345 9.36917C15.0325 9.68162 14.6086 9.85715 14.1667 9.85715C13.7247 9.85715 13.3009 9.68162 12.9883 9.36917L10.6308 7.01167C10.3184 6.69912 10.1429 6.27528 10.1429 5.83334C10.1429 5.3914 10.3184 4.96755 10.6308 4.655L12.9883 2.2975ZM14.1667 3.47667L11.81 5.83334L14.1667 8.19L16.5233 5.83334L14.1667 3.47667ZM7.5 2.5C7.94203 2.5 8.36595 2.6756 8.67851 2.98816C8.99107 3.30072 9.16667 3.72464 9.16667 4.16667V7.5C9.16667 7.94203 8.99107 8.36595 8.67851 8.67851C8.36595 8.99107 7.94203 9.16667 7.5 9.16667H4.16667C3.72464 9.16667 3.30072 8.99107 2.98816 8.67851C2.67559 8.36595 2.5 7.94203 2.5 7.5V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.6756 3.72464 2.5 4.16667 2.5H7.5ZM7.5 4.16667H4.16667V7.5H7.5V4.16667ZM17.5 12.5C17.5 12.058 17.3244 11.6341 17.0118 11.3215C16.6993 11.0089 16.2754 10.8333 15.8333 10.8333H12.5C12.058 10.8333 11.634 11.0089 11.3215 11.3215C11.0089 11.6341 10.8333 12.058 10.8333 12.5V15.8333C10.8333 16.2754 11.0089 16.6993 11.3215 17.0118C11.634 17.3244 12.058 17.5 12.5 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V12.5ZM15.8333 12.5V15.8333H12.5V12.5H15.8333ZM7.5 10.8333C7.94203 10.8333 8.36595 11.0089 8.67851 11.3215C8.99107 11.6341 9.16667 12.058 9.16667 12.5V15.8333C9.16667 16.2754 8.99107 16.6993 8.67851 17.0118C8.36595 17.3244 7.94203 17.5 7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5C2.5 12.058 2.67559 11.6341 2.98816 11.3215C3.30072 11.0089 3.72464 10.8333 4.16667 10.8333H7.5ZM7.5 12.5H4.16667V15.8333H7.5V12.5Z"
        fill="#1D2433"
        fill-opacity="0.8"
      />
    </svg>
  );
};
export default DashboardIcon;
