import { createSlice } from "@reduxjs/toolkit";
import { getAllNotifications } from "./_notificationsAction";

const initialState: any = {
  data: [],
  isLoading: true,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  page:1,
  limit:10,
  errorMessage:""
};

export const notificationList = createSlice({
  name: "notificationList",
  initialState: initialState,
  reducers: {
    
  },

  extraReducers(builder) {
    builder
      .addCase(getAllNotifications.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getAllNotifications.fulfilled, (state, { payload }) => {        
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
      })
      .addCase(getAllNotifications.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

  },
});
