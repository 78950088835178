import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setFormDetails, setIsShowTransferModal } from "../shared/sharedSlice";
import { getPartnerById } from "../partner/_partnerAction";
const API_URL = process.env.REACT_APP_PAYMENT_API_URL;
const BANK_TRANSFER_URL = `${API_URL}/transferToBankAccount`;
const GET_ALL_TRANSACTION = `${API_URL}/getAllTransactionByPartner`;

export const paymentTransferToBank = createAsyncThunk(
  "paymentTransferToBank",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    const store: any = getState();
    try {
      const { data } = await axios.post(`${BANK_TRANSFER_URL}`, values);
      notify(data.responseMessage, 'success');
      await dispatch(getAllTractions({ id: store.sharedActions.formDetails._id, page: 1, limit: 10 }));
      await dispatch(setFormDetails({...store.sharedActions.formDetails,wallet:0}))
      await dispatch(setIsShowTransferModal(false))
      return data;
    } catch (error: any) {
      console.log('error >>> ',error);
      
      notify(error.response.data.responseMessage || "",'error')
      // dispatch(getAllTractions({ id: store.sharedActions.formDetails._id, page: 1, limit: 10 }));
      // dispatch(getPartnerById({id:store.sharedActions.formDetails._id})).then((response:any)=>{
      //   dispatch(setFormDetails({...store.sharedActions.formDetails,wallet:response?.payload?.data?.wallet}))
      // })
      return rejectWithValue(error.message);
    }
  }
);

export const getAllTractions = createAsyncThunk(
  "getAllTractions",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { id, page, limit } = values;
      const { data } = await axios.get(`${GET_ALL_TRANSACTION}/${id}?page=${page}&limit=${limit}`, {});
      data.page = page;
      data.limit = limit
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);