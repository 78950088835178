import { createSlice } from "@reduxjs/toolkit";
import { createInvoice } from "./_invoiceAction";

const initialState: any = {
  data: [],
  isLoading: false,
  isSuccess: false,
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(createInvoice.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
      })
      .addCase(createInvoice.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});
