import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const GET_ALL_TESTIMONIALS = `${API_URL}/getTestimonials`;
const VERIFY_TESTIMONIALS = `${API_URL}/verifyTestimonial`;

export const getTestimonial = createAsyncThunk(
  "get/testimonials",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, _id } = values;
      const { data } = await axios.get(`${GET_ALL_TESTIMONIALS}?page=${page}&limit=${limit}&id=${_id}`, {});
      data['page'] = page;
      data['limit'] = limit;
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const verifyTestimonial = createAsyncThunk(
  "verify/testimonial",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { _id } = values;
      const { data } = await axios.put(`${VERIFY_TESTIMONIALS}/${_id}`, {});
      notify(data.responseMessage, 'success');
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)