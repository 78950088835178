export const REQUIRED = 'Field is required.';
export const WAGE_REQ = 'Wages must be between 1 to 1000';
export const EXP_REQ = 'Experience must be between 1 to 99 ';
export const FIRST_NAME = 'First name should not exceed 30 characters';
export const LAST_NAME = 'First name should not exceed 30 characters';
export const DESCRIPTION_LIMIT = 'Description should be upto 1250 characters'
export const ACC_VALIDATION_MSG = 'Invalid account number'
export const IFSC_VALIDATION_MSG = 'Invalid IFSC code'
export const WITHDRAW_REQUEST = 'withdrawRequest'
export const MOBILE_ERR = 'Provide a valid mobile number'
export const EMAIL_ERR = 'Provide a valid email address'
export const GENDER = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  // { label: "Other", value: "other" },
];

export const LANGUAGE = [
  "Hindi",
  "English",
  "Marathi",
];

export const EXPERTISE = [
  { label: "Problem solving", value: "Problem solving" },
  { label: "Communication", value: "Communication" },
  { label: "Empathy", value: "Empathy" },
  { label: "Patience", value: "Patience" },
  { label: "Research skills", value: "Research skills" },
  { label: "Active listening", value: "Active listening" },
  { label: "Research", value: "Research" },
  { label: "Interpersonal communication", value: "Interpersonal communication" },
  { label: "Critical thinking", value: "Critical thinking" },
];

export const CONVERSIONTYPE = [
  "Chat",
  "Call",
];


export const SIDEBAR = {
  SPECIALITY : "Speciality",
  CATEGORY : "Category",
  PARTNER : "PARTNER",
  USER : "USER",
  SESSION : "Session",
  BLOG:"Blog",
  TRANSACTION: 'transaction',
  LANGAUGE:'Language',
  ADD_TRANSACTION:'AddTransaction',
}

const  capitalizeFirstLetter = (string) =>{
  if (!string) return '';
  const txt = string.toLowerCase();
  return txt?.charAt(0)?.toUpperCase() + txt?.slice(1);
}


export {capitalizeFirstLetter}

export const accountNumberRegex = /^[0-9]{9,18}$/;
// export const ifscRegex =/^[A-Z]{4}0{3}\d{4}$/;
export const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const indianMobileRegex= /^91[6-9]\d{9}$/;




