const NotificationIconGreyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 19.0031V17.0031H6V10.0031C6 8.61979 6.41667 7.39063 7.25 6.31563C8.08333 5.24063 9.16667 4.53646 10.5 4.20313V3.50313C10.5 3.08646 10.6458 2.73229 10.9375 2.44063C11.2292 2.14896 11.5833 2.00313 12 2.00313C12.4167 2.00313 12.7708 2.14896 13.0625 2.44063C13.3542 2.73229 13.5 3.08646 13.5 3.50313V4.20313C14.8333 4.53646 15.9167 5.24063 16.75 6.31563C17.5833 7.39063 18 8.61979 18 10.0031V17.0031H20V19.0031H4ZM12 22.0031C11.45 22.0031 10.9792 21.8073 10.5875 21.4156C10.1958 21.024 10 20.5531 10 20.0031H14C14 20.5531 13.8042 21.024 13.4125 21.4156C13.0208 21.8073 12.55 22.0031 12 22.0031ZM2 10.0031C2 8.33646 2.37083 6.80729 3.1125 5.41563C3.85417 4.02396 4.85 2.86979 6.1 1.95312L7.275 3.55313C6.275 4.28646 5.47917 5.21146 4.8875 6.32813C4.29583 7.44479 4 8.66979 4 10.0031H2ZM20 10.0031C20 8.66979 19.7042 7.44479 19.1125 6.32813C18.5208 5.21146 17.725 4.28646 16.725 3.55313L17.9 1.95312C19.15 2.86979 20.1458 4.02396 20.8875 5.41563C21.6292 6.80729 22 8.33646 22 10.0031H20Z"
        fill="#8ab4f8"
      />
    </svg>
  );
};
export default NotificationIconGreyIcon;
