import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_PAYMENT_API_URL;
const CREATE_INVOICE = `${API_URL}/downloadInvoice`;

export const createInvoice = createAsyncThunk(
  "createInvoice",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const {id} = values;
      const { data } = await axios.get(`${CREATE_INVOICE}/${id}`, values);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

