import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifications } from '../../../redux/features/notifications/_notificationsAction';
import moment from 'moment';
import dummyImage from '../../../app/icons/profileImg.png'
import { Pagination } from 'react-bootstrap';
import { KTCard } from '../../../_metronic/helpers';


function NotificationList() {
  const dispatch = useDispatch<any>();
  const notificationsList: any = useSelector(
    (state: any) => state
  );
  const { totalRecord } = useSelector((state: any) => state.notificationList);


  const handleClick = (page: number) => {
    dispatch(getAllNotifications({ page: page, limit: 10 }));
  };


  React.useEffect(() => {
    dispatch(getAllNotifications({ page: 1, limit: 10 }));
  }, []);

  return (
    <>
      <div className='tab-pane notificationListing'>
        <div className='notificationListingInner'>
          {(notificationsList.notificationList.data?.length > 0) ? (
               <>
               {notificationsList.notificationList.data?.map((item, index) => (
                <div key={`alert${index}`} className='d-flex flex-stack py-4 singleNotification'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <img
                        src={item?.senderProfileImage ? item?.senderProfileImage : dummyImage}
                        alt={`prifilepic`}
                        className="rounded-circle userNotification"
                      />
                    </div>
    
                    <div className='mb-0 me-2'>
                      <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                        {item.message}
                      </a>
                      <div className='text-gray-400 fs-7'>{moment(item?.createdAt).format('MM/DD/YYYY, hh:mmA')}</div>
                    </div>
                  </div>
                </div>
              ))}
               </>
          ) : (
            <div className='nodataFound'>
              <h3>No data found</h3>
            </div>
          )}
       
        </div>
      </div>
    </>

  );
}

export { NotificationList };