import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_PAYMENT_API_URL;
const GET_TRANSACTION_DETAILS = `${API_URL}/getAllTransactions`;

export const getTransactionDetails = createAsyncThunk(
  "getTransactionDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { filter = "" } = values;
      const { data } = await axios.get(`${GET_TRANSACTION_DETAILS}?filter=${filter}`, {});
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)