import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const GET_ALL_USER = `${API_URL}/adminBlogs`;
const APPROVE_BLOG = `${API_URL}/activateBlog`;
const GET_BLOG = `${API_URL}/blog`;
export const GET_ALL_NOTIFICATIONS = `${API_URL}/getAllNotifications`;


export const getAllNotifications = createAsyncThunk(
  "notifications/list",
  async (values: any, { rejectWithValue }) => {
    try {
      const { page, limit ,search=''} = values;
      const { data } = await axios.get(`${GET_ALL_NOTIFICATIONS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
