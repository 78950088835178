import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setBlogImage, setFormDetails } from "../shared/sharedSlice";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const API_URL_AUTH = process.env.REACT_APP_AUTH_API_URL;
const GET_ALL_USER = `${API_URL}/adminBlogs`;
const APPROVE_BLOG = `${API_URL}/activateBlog`;
const GET_BLOG = `${API_URL}/getAdminBlogById`;
const ADD_BLOG = `${API_URL}/blog`;
const FILE_UPLOAD = `${API_URL_AUTH}/fileUpload`;

export const getBlog = createAsyncThunk(
  "getBlog",
  async (values: any, { rejectWithValue }) => {
    try {
      const { page, limit, search = '' } = values;
      const { data } = await axios.get(`${GET_ALL_USER}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveBlog = createAsyncThunk(
  "approveBlog",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const store: any = getState();
      const page = store.blogList.page || 1;
      const limit = store.blogList.limit || 10;
      const { _id } = values;
      const { data } = await axios.patch(`${APPROVE_BLOG}/${_id}`, {});
      dispatch(getBlog({ page: page, limit: limit }))
      notify(data.responseMessage, 'success');
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "getBlogById",
  async (values: any, { rejectWithValue }) => {
    try {
      const { _id } = values;
      const { data } = await axios.get(`${GET_BLOG}/${_id}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addBlog = createAsyncThunk(
  "addBlog",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const store: any = getState();
      const { page, limit } = store.blogList;
      const { data } = await axios.post(`${ADD_BLOG}`, values);
      notify("Blog added successfully", 'success');
      dispatch(setFormDetails({}))
      dispatch(getBlog({ page: page, limit: limit }));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const blogImageUpload = createAsyncThunk(
  "upload/blogImage",
  async (formData: FormData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `${FILE_UPLOAD}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (data.data?.[0]?.url) {
        dispatch(setBlogImage(data.data[0].url));
      }

      return data;
    } catch (error: any) {
      const responseMessage = error.response?.data?.responseMessage || "An error occurred";
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "deleteBlog",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const store: any = getState();
      const { page, limit } = store.blogList;
      const { id } = values
      const { data } = await axios.delete(`${ADD_BLOG}/${id}`);
      notify(data.responseMessage, 'success');
      dispatch(getBlog({ page: page, limit: limit }));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
)