/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  KTIcon,
  toAbsoluteUrl,
} from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNotifications } from '../../../../redux/features/notifications/_notificationsAction'
import dummyImage from '../../../../app/icons/profileImg.png'
import moment from 'moment'
import { SIDEBAR, WITHDRAW_REQUEST } from '../../../../utils/const'
import {  setId } from '../../../../redux/features/shared/sharedSlice'


const HeaderNotificationsMenu: FC = () => {
  const sharedActions  = useSelector((state: any) => state.sharedActions);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const notificationsList: any = useSelector(
    (state: any) => state
  );

  useEffect(() => {
    dispatch(getAllNotifications({ page: 1, limit: 10 }));
  }, [dispatch]);

  const handleNavigate = (item: any) => {
    if (item.notificationType === WITHDRAW_REQUEST) {
      if (item?.senderId) {
        navigate(`/partner/transaction/${item.senderId}`, {replace: true});
        dispatch(setId(SIDEBAR.TRANSACTION))
      }
    }
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundColor: `rgb(47, 111, 237)` }}
      >
        <h3 className='text-white fw-bold px-9 mt-6 mb-6'>
          Notifications
        </h3>
      </div>
      <div className='tab-pane'>
        {(notificationsList.notificationList.data?.length > 0) ? (<>
          <div className='scroll-y mh-325px my-5 px-4'>
            {notificationsList.notificationList.data?.map((item, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <img
                      src={item?.senderProfileImage ? item?.senderProfileImage : dummyImage}
                      alt={`prifilepic`}
                      className="userNotification rounded-circle"
                    />
                  </div>

                  <div className='mb-0 me-2'>
                    <a onClick={()=>{handleNavigate(item)}} className='fs-6 text-gray-800 text-hover-primary'>
                      {item.message}
                    </a>
                    <div className='text-gray-400 fs-7'>{moment(item?.createdAt).format('MM/DD/YYYY, hh:mmA')}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='py-3 text-center border-top'>
            <Link
              to={'/notifications'}
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTIcon iconName='arrow-right' className='fs-5' />
            </Link>
          </div></>) : (<div className='nodataFound p-5 d-flex align-items-center justify-content-center'><h3>No data found</h3></div>)}

      </div>

    </div>
  )
}
export { HeaderNotificationsMenu }
