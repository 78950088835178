import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setCategoryImage,  setCategoryModalStatus,  setFormDetails } from "../shared/sharedSlice";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const API_URL_AUTH = process.env.REACT_APP_AUTH_API_URL;
const GET_ALL_CATEGORY = `${API_URL}/getAllCategories`;
const ADD_CATEGORY = `${API_URL}/category`;
const FILE_UPLOAD = `${API_URL_AUTH}/fileUpload`;

export const getCategory = createAsyncThunk(
  "getCategory",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = "" } = values;
      const { data } = await axios.get(`${GET_ALL_CATEGORY}?page=${page}&limit=${limit}&search=${search}`, {});
      data['page'] = page;
      data['limit'] = limit; 
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addCategory = createAsyncThunk(
  "addCategory",
  async (values: any, { rejectWithValue, dispatch,getState }) => {
    try {
      const store :any= getState();
      const {page,limit} =store.categoryList;
      const { data } = await axios.post(`${ADD_CATEGORY}`, values);
      notify("Category added successfully", 'success');
      dispatch(setFormDetails({}))
      dispatch(getCategory({ page: page, limit: limit }));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (values: any, { rejectWithValue, dispatch ,getState}) => {
    try {
      const store :any= getState();
      const {page,limit} =store.categoryList;
      const id = values.id;
      delete values.id;
      const { data } = await axios.put(`${ADD_CATEGORY}/${id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(setCategoryModalStatus(false))
      dispatch(setFormDetails({}));
      dispatch(getCategory({ page: page, limit: limit }));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
)

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (values: any, { rejectWithValue, dispatch,getState }) => {
    try {
      const store :any= getState();
      const {page,limit} =store.categoryList;
      const { id } = values
      const { data } = await axios.delete(`${ADD_CATEGORY}/${id}`);
      notify(data.responseMessage, 'success');
      dispatch(getCategory({ page: page, limit: limit }));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
)


export const categoryImageUpload = createAsyncThunk(
  "upload/categoryImage",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `${FILE_UPLOAD}`,
        data: values,
        headers: { "Content-Type": "multipart/form-data" },
      })
      dispatch(setCategoryImage(data.data[0]?.url))
      return data
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);