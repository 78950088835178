import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { AddWaitingTime } from "./AddWaitingTime";
// import { ChangePasswordWrapper } from "./ChangePasswordWrapper";
// import './styles.scss'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Change Password",
    path: "/setting/expiry",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const SettingPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="expiry"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Brand list</PageTitle>
              <div className="expiringTitle"><h4> Waitng Expiry Time</h4></div>
              <AddWaitingTime></AddWaitingTime>
              {/* <ChangePasswordWrapper /> */}
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/setting/expiry" />} />
    </Routes>
  );
};

export default SettingPage;
