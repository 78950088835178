import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setFormDetails, setShowLangModal } from "../shared/sharedSlice";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const LANGUAGE = `${API_URL}/language`;

export const addLanguage = createAsyncThunk(
  "addLanguage",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const store: any = getState();
      const { data } = await axios.post(`${LANGUAGE}`, values);
      dispatch(getLanguages({ page: store.language.page, limit: store.language.limit,search:'' }));
      notify(data.responseMessage, 'success')
      dispatch(setShowLangModal(false))
      dispatch(setFormDetails({}))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getLanguages = createAsyncThunk(
  "getLanguages",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit,search='' } = values;
      const { data } = await axios.get(`${LANGUAGE}?page=${page}&limit=${limit}&search=${search}`);
      data.page = page;
      data.limit = limit;
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateLanguage = createAsyncThunk(
  "updateLanguage",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const store: any = getState();
      const { id,name } = values;
      delete values.id;
      const { data } = await axios.put(`${LANGUAGE}/${id}`, {name});
      dispatch(getLanguages({ page: store.language.page, limit: store.language.limit,search:'' }));
      notify(data.responseMessage, 'success')
      dispatch(setShowLangModal(false))
      dispatch(setFormDetails({}))
      return data;
    } catch (error: any) {
      notify(error.response.data.responseMessage,'error')
      return rejectWithValue(error.message);
    }
  }
);
export const deleteLanguage = createAsyncThunk(
  "deleteLanguage",
  async (values: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const { id} = values;
      const store: any = getState();
      const { data } = await axios.delete(`${LANGUAGE}/${id}`, {  });
      dispatch(getLanguages({ page: store.language.page, limit: store.language.limit,search:'' }));
      notify(data.responseMessage, 'success')
      dispatch(setShowLangModal(false))
      dispatch(setFormDetails({}))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);