import "./style.scss";
import { Slide, toast } from "react-toastify";
import Swal from "sweetalert2";
import { getSpeciality } from "../redux/features/speciality/_specialityAction";
import { getCategory } from "../redux/features/category/_categoryAction";
import { getUsers } from "../redux/features/user/_userAction";
import { getParnters } from "../redux/features/partner/_partnerAction";
import { SIDEBAR } from "./const";
import { getBlog } from "../redux/features/blog/_blogAction";
import moment from 'moment'
import { getLanguages } from "../redux/features/language/_languageAction";

export const commonSwtichCases = (id: any, searchValue: any, dispatch: any) => {
    switch (id) {
        case SIDEBAR.SPECIALITY:
            dispatch(getSpeciality({ search: searchValue.trim(),page: 1, limit: 10 }))
            break;
        case SIDEBAR.CATEGORY:
            dispatch(getCategory({ search: searchValue.trim(),page: 1, limit: 10 }))
            break;
        case SIDEBAR.PARTNER:
            dispatch(getParnters({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case SIDEBAR.USER:
            dispatch(getUsers({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case SIDEBAR.BLOG:
            dispatch(getBlog({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case SIDEBAR.LANGAUGE:
            dispatch(getLanguages({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        default:
    }
}

export const conFirmMessage = (values) => {
    return Swal.fire(values)
}


export const docUrl = (images: any) => {
    const urlArray: any = [];
    for (let i = 0; i < images.length; i++) {
        urlArray.push(images[i].url);
    }
    return urlArray
}

export const toastifyOptions: any = {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Slide,
};

export const notify = (message: any, type: any) => {
    if (type === 'error') {
        return toast.error(message, toastifyOptions);
    } else if (type === 'info') {
        return toast.info(message, toastifyOptions);
    } else if (type === 'warning') {
        return toast.warning(message, toastifyOptions);
    } else if (type === 'success') {
        return toast.success(message, toastifyOptions);
    }
};

export const calculateTime = (dateString: any) => {
    return moment(dateString).fromNow()
}
