const TransactionsIcon = () => {
  return (
<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_61_33" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
<rect width="100" height="100" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_61_33)">
<path d="M25 91.6668C21.5278 91.6668 18.5764 90.4516 16.1458 88.021C13.7153 85.5905 12.5 82.6391 12.5 79.1668V66.6668H25V8.3335L31.25 14.5835L37.5 8.3335L43.75 14.5835L50 8.3335L56.25 14.5835L62.5 8.3335L68.75 14.5835L75 8.3335L81.25 14.5835L87.5 8.3335V79.1668C87.5 82.6391 86.2847 85.5905 83.8542 88.021C81.4236 90.4516 78.4722 91.6668 75 91.6668H25ZM75 83.3335C76.1806 83.3335 77.1701 82.9342 77.9687 82.1356C78.7674 81.337 79.1667 80.3474 79.1667 79.1668V20.8335H33.3333V66.6668H70.8333V79.1668C70.8333 80.3474 71.2326 81.337 72.0312 82.1356C72.8299 82.9342 73.8194 83.3335 75 83.3335ZM37.5 37.5002V29.1668H62.5V37.5002H37.5ZM37.5 50.0002V41.6668H62.5V50.0002H37.5ZM70.8333 37.5002C69.6528 37.5002 68.6632 37.1009 67.8646 36.3023C67.066 35.5036 66.6667 34.5141 66.6667 33.3335C66.6667 32.1529 67.066 31.1634 67.8646 30.3647C68.6632 29.5661 69.6528 29.1668 70.8333 29.1668C72.0139 29.1668 73.0035 29.5661 73.8021 30.3647C74.6007 31.1634 75 32.1529 75 33.3335C75 34.5141 74.6007 35.5036 73.8021 36.3023C73.0035 37.1009 72.0139 37.5002 70.8333 37.5002ZM70.8333 50.0002C69.6528 50.0002 68.6632 49.6009 67.8646 48.8023C67.066 48.0036 66.6667 47.0141 66.6667 45.8335C66.6667 44.6529 67.066 43.6634 67.8646 42.8648C68.6632 42.0661 69.6528 41.6668 70.8333 41.6668C72.0139 41.6668 73.0035 42.0661 73.8021 42.8648C74.6007 43.6634 75 44.6529 75 45.8335C75 47.0141 74.6007 48.0036 73.8021 48.8023C73.0035 49.6009 72.0139 50.0002 70.8333 50.0002ZM25 83.3335H62.5V75.0002H20.8333V79.1668C20.8333 80.3474 21.2326 81.337 22.0313 82.1356C22.8299 82.9342 23.8194 83.3335 25 83.3335Z" fill="#1C1B1F"/>
</g>
</svg>

  );
};
export default TransactionsIcon;
