import { createSlice } from "@reduxjs/toolkit";
import { getTestimonial } from "./_testimonialAction";

const initialState: any = {
  data: [],
  isLoading: true,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
};

export const testimonialList = createSlice({
  name: "testimonialList",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTestimonial.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getTestimonial.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
        state.page = payload.page
        state.limit = payload.limit;
      })
      .addCase(getTestimonial.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});
