import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setShowUserModal } from "../shared/sharedSlice";
const API_URL_LIST = process.env.REACT_APP_LISTING_API_URL;
const API_AUTH_URL = process.env.REACT_APP_AUTH_API_URL;

const GET_ALL_USER = `${API_URL_LIST}/getAllUsers`;
const UPDATE_USER_STATUS = `${API_AUTH_URL}/changeUserStatus`;
const CREATE_USER = `${API_AUTH_URL}/createUserByAdmin`;
const UPDATE_USER = `${API_AUTH_URL}/updateUserByAdmin`;
const DELETE_USER = `${API_AUTH_URL}/deleteUserByAdmin`;

export const getUsers = createAsyncThunk(
  "getUsers",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page = 1, limit=10 ,search=''} = values;
      const { data } = await axios.get(`${GET_ALL_USER}?page=${page}&limit=${limit}&search=${search}`, {});
      data.page = page;
      data.limit = limit;
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUserStatus",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { id, status } = values;
      const { data } = await axios.put(`${UPDATE_USER_STATUS}/${id}`, { status });
      dispatch(getUsers({ page: 1, limit: 10 }))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "createUser",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${CREATE_USER}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getUsers({ page: 1, limit: 10 }))
      dispatch(setShowUserModal(false))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserByAdmin = createAsyncThunk(
  "updateUserByAdmin",
  async (values: any, { rejectWithValue, dispatch,getState }) => {
    try {
      const store :any= getState();
      const {page ,limit} = store.userList;
      const _id = values._id;
      delete values._id
      const { data } = await axios.patch(`${UPDATE_USER}/${_id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(setShowUserModal(false))
      dispatch(getUsers({ page: page, limit: limit }))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUserByAdmin = createAsyncThunk(
  "deleteUserByAdmin",
  async (values: any, { rejectWithValue, dispatch ,getState}) => {
    try {
      const store :any= getState();
      const {page ,limit} = store.userList;
      const {_id} = values;
      const { data } = await axios.delete(`${DELETE_USER}/${_id}`, {});
      notify(data.responseMessage, 'success');
      dispatch(getUsers({ page: page, limit: limit }))
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);