const BlogIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.74926 2.38623H14.1356C14.1157 2.4047 14.0966 2.42387 14.0774 2.44305L12.9978 3.52259H3.74929C3.12145 3.52259 2.61293 4.03112 2.61293 4.65896V6.64768H9.87293L8.73656 7.78405H2.61293V17.159C2.61293 17.7868 3.12145 18.2953 3.74929 18.2953H16.2493C16.8771 18.2953 17.3857 17.7868 17.3857 17.159V7.91041L18.4652 6.83087C18.4844 6.81169 18.5036 6.79251 18.522 6.77263V17.159C18.522 18.414 17.5043 19.4317 16.2493 19.4317H3.74929C2.49433 19.4317 1.47656 18.414 1.47656 17.159V4.65899C1.47656 3.40403 2.49429 2.38623 3.74926 2.38623Z"
        fill="black"
      />
      <path
        d="M4.88661 10.625H8.09752L8.02011 11.3203C8.00377 11.4695 8.01088 11.6179 8.04 11.7614H4.88654C4.57261 11.7614 4.31836 11.5071 4.31836 11.1932C4.31836 10.8793 4.57269 10.625 4.88661 10.625Z"
        fill="black"
      />
      <path
        d="M4.31836 14.6019C4.31836 14.2879 4.57261 14.0337 4.88654 14.0337H15.1138C15.4277 14.0337 15.682 14.2879 15.682 14.6019C15.682 14.9158 15.4277 15.1701 15.1138 15.1701H4.88654C4.57261 15.1701 4.31836 14.9158 4.31836 14.6019Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8632 3.04514C16.984 2.16659 15.5592 2.16659 14.6807 3.04514L9.32703 8.39878C9.23612 8.48969 9.17859 8.60899 9.16439 8.73756L8.86681 11.4145C8.84763 11.5856 8.90729 11.7568 9.02945 11.879C9.15161 12.0011 9.32278 12.0608 9.49394 12.0416L12.1709 11.744C12.2994 11.7298 12.4187 11.6723 12.5096 11.5814L17.8633 6.22774C18.7418 5.34919 18.7418 3.92441 17.8632 3.04514ZM15.4839 3.84841C15.9193 3.41376 16.6246 3.41376 17.0599 3.84841C17.4946 4.28378 17.4946 4.98903 17.0599 5.42441L11.8469 10.6368L10.0748 10.8335L10.2716 9.06149L15.4839 3.84841Z"
        fill="black"
      />
    </svg>
  );
};
export default BlogIcon;
