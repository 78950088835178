import { createSlice } from "@reduxjs/toolkit";
import {  getParnters, updatePartnerDetails } from "./_partnerAction";
import { getLanguages } from "../language/_languageAction";

const initialState: any = {
  data: [],
  isLoading: false,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  page:1,
  limit:10,
  search:'',
  language :[],
  isPartnerUpdateLading: false,
};

export const partnerList = createSlice({
  name: "partnerList",
  initialState: initialState,
  reducers: {
    setPartnerData: (state, { payload }) => {
      state.data = payload
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getParnters.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getParnters.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
        state.page = payload.page
        state.limit = payload.limit
      })
      .addCase(getParnters.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(getLanguages.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getLanguages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.language = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
        state.page = payload.page
        state.limit = payload.limit
      })
      .addCase(getLanguages.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(updatePartnerDetails.pending, (state) => {
        state.isPartnerUpdateLading = true;
        state.isSuccess = false;
      })

      .addCase(updatePartnerDetails.fulfilled, (state, { payload }) => {
        state.isPartnerUpdateLading = false;
        state.isSuccess = true;
      })
      .addCase(updatePartnerDetails.rejected, (state, { payload }) => {
        state.isPartnerUpdateLading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});

export const {setPartnerData} = partnerList.actions;
