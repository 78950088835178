import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {setFormDetails, setPartnerProfileImages } from "../shared/sharedSlice";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_LISTING_API_URL;
const APPOINTMENT_BASE_URL = process.env.REACT_APP_APPOINTMENT_API_URL;
const API_URL_AUTH = process.env.REACT_APP_AUTH_API_URL;
const GET_ALL_PARTNER = `${API_URL}/getAllAdminPartners`;
const VERIFY_PARTNER = `${API_URL_AUTH}/verifyPartnerByAdmin`;
const COMMON_FILE_UPLOAD = `${API_URL_AUTH}/fileUpload`;
const UPDATE_PARTNER_DETAILS = `${API_URL_AUTH}/updatePartnerAccount`;
const UPDATE_WAITING_TIME = `${API_URL_AUTH}/updateTimeSettings`;
const GET_TIMINGS = `${API_URL_AUTH}/timeSettings`;
const LANGUAGE = `${API_URL}/language`

export const updatetimingsDetails = createAsyncThunk(
  "updatePartnerDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${UPDATE_WAITING_TIME}`, values);
      notify(data.responseMessage, 'success');
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const getTimimgs = createAsyncThunk(
  "getTimimgs",
  async (_void, { rejectWithValue, dispatch }) => {
    try {
      const data  = await axios.get(`${GET_TIMINGS}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

