import { createSlice } from "@reduxjs/toolkit";
import { addCategory, getCategory } from "./_categoryAction";

const initialState: any = {
  data: [],
  isLoading: true,
  addCategoryLoading: false,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  page:1,
  limit:10,
};

export const categoryList = createSlice({
  name: "categoryList",
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
        state.page = payload.page
        state.limit = payload.limit
      })
      .addCase(getCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(addCategory.pending, (state) => {
        state.addCategoryLoading = true;
        state.isSuccess = false;
      })

      .addCase(addCategory.fulfilled, (state, { payload }) => {
        state.addCategoryLoading = false;
        state.isSuccess = true;
      })
      .addCase(addCategory.rejected, (state, { payload }) => {
        state.addCategoryLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      }); 
  },
});
