import { createSlice } from "@reduxjs/toolkit";
import { getTimimgs, updatetimingsDetails } from "./_timingsAction";
// import { updatePartnerDetails } from "./_timingsAction";

const initialState: any = {
  data: [],
  isLoading: false,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  language :[],
  isTimingsLoading: false,
  timingData:null
};

export const timings = createSlice({
  name: "timings",
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    builder

      .addCase(updatetimingsDetails.pending, (state) => {
        state. isTimingsLoading = true;
        state.isSuccess = false;
      })

      .addCase(updatetimingsDetails.fulfilled, (state, { payload }) => {
        state. isTimingsLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatetimingsDetails.rejected, (state, { payload }) => {
        state. isTimingsLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(getTimimgs.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(getTimimgs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.timingData = payload.data.data;
      })
      .addCase(getTimimgs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});
