import { createSlice } from "@reduxjs/toolkit";
import { getAllTractions, paymentTransferToBank } from "./_paymentAction";

const initialState: any = {
  data: [],
  transactions : [],
  isLoading: false,
  isTransactionLoading:false,
  isSuccess: false,
  page:1,
  limit:10,
  totalRecord:0,
};

export const paymentsSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(paymentTransferToBank.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })

      .addCase(paymentTransferToBank.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
      })
      .addCase(paymentTransferToBank.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })

      .addCase(getAllTractions.pending, (state) => {
        state.isTransactionLoading = true;
        state.isSuccess = false;
      })

      .addCase(getAllTractions.fulfilled, (state, { payload }) => {
        state.isTransactionLoading = false;
        state.isSuccess = true;
        state.transactions = payload.data;
        state.page= payload.data.page
        state.limit= payload.data.limit
        state.totalRecord = payload.totalRecord
      })
      .addCase(getAllTractions.rejected, (state, { payload }) => {
        state.isTransactionLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});
