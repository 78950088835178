import { PartnersListHeader } from "../common/common-list/components/header/ListHeader";
import { CommonTable } from "../common/common-list/table/Table";
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionColumns } from "../common/common-list/table/columns/_sessionColumns";
import { getSpeciality } from "../../../../redux/features/speciality/_specialityAction";
import { Pagination } from "../common/common-list/components/pagination/Pagination";
import { setId, setSettingEventKey } from "../../../../redux/features/shared/sharedSlice";
import cubeIcon from "../../../../assets/images/cubeIcon.png";
import arrowUpCircle from "../../../../assets/images/arrowUpCircle.png";
import arrowDownRedCircle from "../../../../assets/images/arrowDownRedCircle.png";
// import {  SessionModal } from "./SessionModal";
// import { getSessions } from "../../../../redux/features/session/_sessionAction";
// import { transactionsColumns } from "../common/common-list/table/columns/_transactionColumns";
import { Nav, Tab } from "react-bootstrap";
// import { TransactionModel } from "./TransactionModel";
import { getTransactionDetails } from "../../../../redux/features/transaction/_transactionAction";

const TransactionsList = () => {
    const dispatch: any = useDispatch();
    const sharedActions = useSelector((state: any) => state.sharedActions);
    const [activeTab, setActiveTab] = useState(sharedActions?.defaultSettingEvent || "today");
    const {data} = useSelector((state:any)=> state.transactionDetails)

    console.log(data,'4444')
    //   const data = useSelector((state: any) => state.sessionList?.data);
    //   const sharedActions = useSelector((state: any) => state.sharedActions);
    //   const { totalRecord } = useSelector((state: any) => state.sessionList);

    useEffect(() => {
        dispatch(setId("transaction"));
        dispatch(getTransactionDetails({ filter: activeTab ? activeTab : 'today' }));
    }, [dispatch, activeTab]);


    useEffect(() => {
        if (sharedActions?.defaultSettingEvent) {
            setActiveTab(sharedActions?.defaultSettingEvent)
        }
    }, [sharedActions?.defaultSettingEvent])

    //   const handleClick = (page: number) => {
    //     dispatch(getSpeciality({ page: page, limit: 10 }));
    //   };

    const setEventKey = (key: any) => {
        dispatch(setSettingEventKey(key))
    };

    return (
        <>
            <div className="pageWrapper">
                <div className="weeklyTab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey={sharedActions?.defaultSettingEvent || "first"} activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                        <div className="tabWrapperOuter">
                            <Nav variant="pills" className="d-flex tabWrapper">
                                <Nav.Item onClick={() => setEventKey("today")}>
                                    <Nav.Link eventKey="today">Today</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setEventKey("weekly")}>
                                    <Nav.Link eventKey="weekly">Weekly</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setEventKey("monthly")}>
                                    <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="today">
                                <div className="blocksWrapper">
                                    <div className="row">
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Total Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                    <h4>{data?.totalTransactions}</h4>
                                                    <div className="upDown green">
                                                        {/* <img src={arrowUpCircle} alt="arrowUpCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon  flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Completed Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                    <h4>{data?.capturedTransactions}</h4>
                                                    <div className="upDown red">
                                                        {/* <img src={arrowDownRedCircle} alt="arrowDownRedCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon  flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Pending Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                    <h4>{data?.pendingTransactions}</h4>
                                                    <div className="upDown green">
                                                        {/* <img src={arrowUpCircle} alt="arrowUpCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                            <div className="singleBlock">
                                <div className="titleIcon d-flex justify-content-between align-items-center flex-wrap">
                                    <div className=""><img src={cubeIcon} alt="cubeIcon"/> <label>Not Sure</label></div>
                                    <button className="viewBtn">View History</button>
                                </div>
                                <div className="priceUpDown d-flex justify-content-between align-items-center flex-wrap">
                                    <h4>₹1,23,450-</h4>
                                    <div className="upDown red">
                                    <img src={arrowDownRedCircle} alt="arrowDownRedCircle"/> +5% <span>Last Month</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="weekly">
                                <div className="blocksWrapper">
                                    <div className="row">
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Total Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                    <h4>{data?.totalTransactions}</h4>
                                                    <div className="upDown green">
                                                        {/* <img src={arrowUpCircle} alt="arrowUpCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon  flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Completed Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                    <h4>{data?.capturedTransactions}</h4>
                                                    <div className="upDown red">
                                                        {/* <img src={arrowDownRedCircle} alt="arrowDownRedCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon  flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Pending Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                    <h4>{data?.pendingTransactions}</h4>
                                                    <div className="upDown green">
                                                        {/* <img src={arrowUpCircle} alt="arrowUpCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon d-flex justify-content-between align-items-center flex-wrap">
                                                    <div className=""><img src={cubeIcon} alt="cubeIcon" /> <label>Not Sure</label></div>
                                                    <button className="viewBtn">View History</button>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center flex-wrap">
                                                    <h4>₹1,23,450</h4>
                                                    <div className="upDown red">
                                                        <img src={arrowDownRedCircle} alt="arrowDownRedCircle" /> +5% <span>Last Month</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="monthly">
                                <div className="blocksWrapper">
                                    <div className="row">
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Total Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                <h4>{data?.totalTransactions}</h4>
                                                    <div className="upDown green">
                                                        {/* <img src={arrowUpCircle} alt="arrowUpCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon  flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Completed Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                <h4>{data?.capturedTransactions}</h4>
                                                    <div className="upDown red">
                                                        {/* <img src={arrowDownRedCircle} alt="arrowDownRedCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon  flex-wrap">
                                                    <img src={cubeIcon} alt="cubeIcon" /> <label>Pending Transactions</label>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center  flex-wrap">
                                                <h4>{data?.pendingTransactions}</h4>
                                                    <div className="upDown green">
                                                        {/* <img src={arrowUpCircle} alt="arrowUpCircle" /> +5% <span>Last Month</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xxl-3 col-xl-6 col-md-6 mb-3">
                                            <div className="singleBlock">
                                                <div className="titleIcon d-flex justify-content-between align-items-center flex-wrap">
                                                    <div className=""><img src={cubeIcon} alt="cubeIcon" /> <label>Not Sure</label></div>
                                                    <button className="viewBtn">View History</button>
                                                </div>
                                                <div className="priceUpDown d-flex justify-content-between align-items-center flex-wrap">
                                                    <h4>₹1,23,450</h4>
                                                    <div className="upDown red">
                                                        <img src={arrowDownRedCircle} alt="arrowDownRedCircle" /> +5% <span>Last Month</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                {/* <KTCard>
          <PartnersListHeader />
          <CommonTable data={data} columns={transactionsColumns} />
          {sharedActions.showSessionModal && <TransactionModel/>}
          {totalRecord > 10 && (
            <Pagination totalRecord={totalRecord} handleClick={handleClick} />
          )}
        </KTCard> */}
            </div>
        </>
    );
};

export { TransactionsList };
