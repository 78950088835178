import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import SettingPage from "../modules/setting/SettingPage";
import NotificationsPage from "../modules/notifications/NotificationsPage";
import Transactions from "../modules/apps/transactions/transactions";

const PrivateRoutes = () => {
  const ChangePasswordPage = lazy(() => import("../modules/changePassword/ChangePasswordPage"));
  const PartnerPage = lazy(() => import("../modules/apps/partner/PartnerPage"))
  const SpecialityPage = lazy(() => import("../modules/apps/speciality/SpecialityPage"))
  const UsersPage = lazy(() => import("../modules/apps/user/UserPage"))
  const CategoryPage = lazy(() => import("../modules/apps/category/CategoryPage"))
  const BlogPage = lazy(() => import("../modules/apps/blog/BlogPage"));
  const SessionPage = lazy(() => import("../modules/apps/session/SessionPage"));
  const LanguagePage = lazy(()=> import("../modules/apps/Language/LanguagePage"))

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="auth/*" element={<Navigate to="/partners" />} />

          <Route
            path="user/*"
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path="user/*"
            element={
              <SuspensedView>
                <ChangePasswordPage />
              </SuspensedView>
            }
          />
          <Route
            path="partner/*"
            element={
              <SuspensedView>
                <PartnerPage />
              </SuspensedView>
            }
          >
          </Route>
          <Route
            path="speciality/*"
            element={
              <SuspensedView>
                <SpecialityPage />
              </SuspensedView>
            }
          />
          <Route
            path="category/*"
            element={
              <SuspensedView>
                <CategoryPage />
              </SuspensedView>
            }
          />
          <Route
            path="blog/*"
            element={
              <SuspensedView>
                <BlogPage />
              </SuspensedView>
            }
          />
          <Route
            path="session/*"
            element={
              <SuspensedView>
                <SessionPage />
              </SuspensedView>
            }
          />

          <Route
            path="setting/*"
            element={
              <SuspensedView>
                <SettingPage />
              </SuspensedView>
            }
          />

          <Route
            path="notifications/*"
            element={
              <SuspensedView>
                <NotificationsPage />
              </SuspensedView>
            }
          />

          <Route
            path="transactions/*"
            element={
              <SuspensedView>
                <Transactions />
              </SuspensedView>
            }
          />
          <Route
            path="language/*"
            element={
              <SuspensedView>
                <LanguagePage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/user/list" />} />
        </Route>
      </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
