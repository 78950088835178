import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  id: '',
  showModal: false,
  categoryModal: false,
  partnerDetailsModal: false,
  showUserModal: false,
  showBlogModal: false,
  formDetails: {},
  files: [],
  showSessionModal: false,
  isShowTransferModal: false,
  defaultSettingEvent: 'today',
  showLangModal: false
};

export const sharedSlice = createSlice({
  name: "sharedSlice",
  initialState: initialState,
  reducers: {
    setId: (state, { payload }) => {
      state.id = payload
    },

    setModalStatus: (state, { payload }) => {
      state.showModal = payload
    },

    setFormDetails: (state, { payload }) => {
      state.formDetails = payload
    },
    setCategoryModalStatus: (state, { payload }) => {
      state.categoryModal = payload
    },
    setPartnerModalStatus: (state, { payload }) => {
      state.partnerDetailsModal = payload
    },
    docoumentUpload: (state, { payload }) => {
      state.files = payload
    },
    setPartnerProfileImages: (state, { payload }) => {
      state.formDetails.profilePicture = payload.data[0]?.url
    },
    setCategoryImage: (state, { payload }) => {
      state.formDetails.image = payload;
    },
    partnerCoverImages: (state, { payload }) => {
      const images = payload.data.map((item: any) => item.url)
      state.formDetails.coverImage = images;
    },
    setShowSessionModal: (state, { payload }) => {
      state.showSessionModal = payload;
    },
    setIsShowTransferModal: (state, { payload }) => {
      state.isShowTransferModal = payload;
    },
    showTransactionModel: (state, { payload }) => {
      state.isShowTransferModal = payload;
    },
    setSettingEventKey: (state, { payload }) => {
      state.defaultSettingEvent = payload;
    },
    setShowLangModal: (state, { payload }) => {
      state.showLangModal = payload;
    },
    setShowUserModal: (state, { payload }) => {
      state.showUserModal = payload;
    },
    setShowBlogModal: (state, { payload }) => {
      state.showBlogModal = payload;
    },
    setBlogImage: (state, { payload }) => {
      state.formDetails.image = payload;
    },
  },
});

export const {
  setId,
  setModalStatus,
  setFormDetails,
  setCategoryModalStatus,
  setPartnerModalStatus,
  docoumentUpload,
  setPartnerProfileImages,
  setCategoryImage,
  partnerCoverImages,
  setShowSessionModal,
  setShowBlogModal,
  setIsShowTransferModal,
  setSettingEventKey,
  setShowLangModal,
  setShowUserModal,
  setBlogImage
} = sharedSlice.actions;