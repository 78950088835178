import { createSlice } from "@reduxjs/toolkit";
import { getSessions, partnerSessionsList } from "./_sessionAction";

const initialState: any = {
  data: [],
  isLoading: true,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  page:1,
  limit:10,
  partnerSessionList:{}
};

export const sessionList = createSlice({
  name: "sessionList",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSessions.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getSessions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.responseMessage = payload.responseMessage
        state.responseCode = payload.responseCode
        state.totalRecord = payload.totalRecord
        state.page = payload.page
        state.limit = payload.limit
      })
      .addCase(getSessions.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(partnerSessionsList.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(partnerSessionsList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.partnerSessionList = payload
      })
      .addCase(partnerSessionsList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});
